/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import NavigationBar from '../navbar';
import AboutPost from '../aboutpost';
import Resume from '../resume';
import '../../About.css';

function AboutWrapper() {
  return (
    <div className="aboutpage">
      <NavigationBar pagename="about" />
      <div className="post">
        <AboutPost />
        <Resume />
      </div>
    </div>
  );
}

export default AboutWrapper;
