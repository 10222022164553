import React from 'react';
import '../App.css';
import '../bootstrap.min.css';


function HomePage() {
  return (
    <div className="homepost">
      <div>
        <h1>I am Bhavin Africawala</h1>
      </div>
      <div>
        <h5>I make modern website with Front-End Technologies and C#</h5>
      </div>

    </div>
  );
}

export default HomePage;
