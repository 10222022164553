import React from 'react';
import HomePage from '../homepage';
import NavigationBar from '../navbar';
import BackGround from '../background';
import '../../App.css';
import '../../bootstrap.min.css';
import '../../Home.css';

function HomeWrapper() {
  return (
    <div className="App">
      <BackGround />
      <NavigationBar pagename="home" />
      <HomePage />
    </div>
  );
}

export default HomeWrapper;
