import React from 'react';
import {
  BrowserRouter, Route,
} from 'react-browser-router';
import HomeWrapper from './components/wrapper/homewrapper';
import AboutWrapper from './components/wrapper/aboutwrapper';
import ContactWrapper from './components/wrapper/contactwrapper';
import Footer from './components/footer';
import './App.css';
import './bootstrap.min.css';

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <>
          <Route exact path="/" component={HomeWrapper} />
          <Route exact path="/about" component={AboutWrapper} />
          <Route exact path="/contact" component={ContactWrapper} />
        </>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
