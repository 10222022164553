/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';


function Resume() {
  return (
    <div className="resume">
      <div className="resumebox">
        <div className="resume-inside">
          <h3>||* Resume *||</h3>
          <h4>||* PROFESSIONAL SUMMARY *||</h4>
          <ul>
            <li>
            Receive a Master Degree in Business Admintraton
            for Information Technology from Sikkim Manipal University.
            </li>
            <li>
            15+ years experience in requirement analysis, design, development,
            testing and maintenance of N-Tier applications in Microsoft Technologies
            using C#, ASP.NET, AJAX, ADO.NET, WCF, Entity Framework and RestFul API.
            </li>
            <li>
            Excellent exposure to front-end technologies like HTML5,
            CSS, Javascript , ReactJs etc.
            </li>
            <li>
            Excellent Problem Solving and Analytical Skills
            </li>
          </ul>
          <h4>||* PROFESSIONAL EXPERIENCE *||</h4>
          <h5>-: SENIOR DOTNET DEVELOPER, ALLUMA - SACRAMENTO, CA :-</h5>
          <ul>
            <li>
            Involved in developing WCF services using C#.
            </li>
            <li>
            Involved in upgrading application from .NET 3.0 to .NET 4.7.
            </li>
            <li>
            Involved in developing different browser compatible front-end
            using ASP.net and MVC and client-side coding using JavaScript and JQuery.
            </li>
            <li>
            Working on converting the front-end design from classic HTML to Bootstrap css.
            </li>
            <li>
            Involved in upgrading the vanilla JavaScript to AngularJS.
            </li>
            <li>
            Involved in development using ORM like Entity Framework and Dapper
            </li>
            <li>
            Well versed with TFS and GIT.
            </li>
          </ul>
          <h5>-: SENIOR DOTNET DEVELOPER, CAESARS ENTERTAINMENT - ATLANTIC CITY, NJ :-</h5>
          <ul>
            <li>
        Developed Admin application in MVC framework using Razor cshtml coding style.
            </li>
            <li>Worked on Dependency Injection using Autofac.</li>
            <li>
      Involved in developing different browser compatible front end using
      ASP.net and MVC and client side coding using JavaScript and JQuery.
            </li>
            <li>Worked with Bootstrap css for front-end designing. </li>
            <li>Used AngularJS for managing client side scripting and design.</li>
          </ul>

          <h5>-: SENIOR DOTNET DEVELOPER, INETGEN INC - MUMBAI, INDIA :-</h5>
          <ul>
            <li>
            Involved in developing SQL Server compatible application.
            Developed various modules in VB 6.0 and C#.
            </li>
            <li>Developed web application using Classic ASP, ASP.net, COM+.</li>
            <li>Implemented proper Error Handlers in C# 2.0/3.5.</li>
            <li>
            Consumed Restful and SOAP webservices exposed by Social Networking Sites
            like Facebook, Linkedin and Twitter and
            Job Board Sites like Monster and CareerBuilder
            </li>
          </ul>
          <h4>||* EDUCATION *||</h4>
          <ul>
            <li>
            Sikkim Manipal University - Master of Business
            Adminstration in Information Technologies
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Resume;
